<template>
<div>
  <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>企业设置</el-breadcrumb-item>
            <el-breadcrumb-item>品牌管理</el-breadcrumb-item>
        </el-breadcrumb>
  <el-card>
    <div slot="header" class="clearfix">
      <span>品牌管理</span>
    </div>
    <el-form :model="brandForm" ref="brandValidateForm" :rules="rules"> 
      <el-form-item class="logo-title">
        企业logo：建议尺寸130*30，格式为PNG，大小在100K以内
      </el-form-item>
      <div class="flex-row">
        <el-form-item style="position: relative; margin-right: 1.5rem;">
          <Upload
            ref="lightLogoUpload"
            class="smail-upload-wrapper"
            :maxSize="1024 * 100"
            :imageUrl="light_logo"
            :isNeedReview="false"
          />
          <el-form-item
            class="font-size-14 font-opacity-85"
            style="text-align: center;"
            >浅色logo</el-form-item
          >
        </el-form-item>
        <!-- <el-form-item style="position: relative">
          <Upload
            ref="darkLogoUpload"
            :maxSize="1024 * 100"
            :imageUrl="dark_logo"
            :isNeedReview="false"
          />
          <el-form-item
            class="font-size-14 font-opacity-85"
            style="text-align: center"
            >深色logo</el-form-item
          >
        </el-form-item> -->
      </div>
      <el-form-item class="logo-title">
        PC登录背景：建议尺寸1240*1080，格式PNG，大小在5M以内
      </el-form-item>
      <el-form-item style="position: relative">
        <Upload
          ref="bigLogoUpload"
          class="big-upload-wrapper"
          :maxSize="1024 * 1024 * 5"
          :imageUrl="login_background"
          :isNeedReview="true"
        />
      </el-form-item>
      <el-form-item  label="登录页标语 :" prop="loginSlogan" label-width="86px">
        <el-input
          class="search-input"
          type="textarea"
          :rows="4"
          style="width: 359px !important"
          placeholder="2-100个字符"
          v-model.trim="brandForm.loginSlogan"
        ></el-input>
      </el-form-item>
      <el-form-item class="flex-column-all-center">
        <el-button>取消</el-button>
        <el-button type="primary" class="buttonColor" @click="onClickSave">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</div>
</template>
<script>
import Home from "../home/Home.vue";
import Upload from "./components/Upload.vue";
import request from "@request/api/index";
export default {
  data() {
    return {
      brandForm:{
        loginSlogan:''
      },
      rules:{
        loginSlogan:[
          { required:true,message:'请输入标语',trigger:'blur'},
          { min:2,max:100,message:'字符在2到100之间',trigger:['blur','change']}
        ]
      }
    };
  },
  components: { Home, Upload },
  computed: {
    light_logo() {
      return this.$store.state.user.companyBasicInfo.light_logo;
    },
    dark_logo() {
      return this.$store.state.user.companyBasicInfo.dark_logo;
    },
    login_background() {
      return this.$store.state.user.companyBasicInfo.login_background;
    },
    login_slogan() {
      this.brandForm.loginSlogan = this.$store.state.user.companyBasicInfo.login_slogan;
    },
  },
  created() {
    this.requestBrandInfo();
  },
  mounted(){
    localStorage.setItem('fileUrl',this.login_background)
  },
  methods: {
    requestBrandInfo() {
      request.enterprise.requestBrandInfo();
      this.login_slogan;
    },
    requestBrandSet(params){
      request.enterprise.requestBrandSet(params);
    },
    onClickSave() {
      const light_logo = this.$refs.lightLogoUpload.fileOssUrl !== ""
          ? this.$refs.lightLogoUpload.fileOssUrl
          : this.light_logo;
          
      const dark_logo = this.$refs.darkLogoUpload && this.$refs.darkLogoUpload.fileOssUrl
          ? this.$refs.darkLogoUpload.fileOssUrl
          : this.dark_logo;

      const login_background = this.$refs.bigLogoUpload.fileOssUrl !== ""
          ? this.$refs.bigLogoUpload.fileOssUrl
          : this.login_background;
      this.$refs.brandValidateForm.validate((valid)=>{
        
          if(valid){
            const login_slogan = this.brandForm.loginSlogan;
            const params = {light_logo, dark_logo, login_background, login_slogan};
            this.requestBrandSet(params);
          }
      })
 
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/page.scss";

::v-deep .el-upload--picture-card{
  background-color: #F0F8FF;
}
</style>
