<template>
  <el-upload
    action=""
    accept=".PNG,.png"
    :show-file-list="false"
    :on-change="imgSaveToUrl"
    :http-request="uploadHttp"
    :before-upload="beforeAvatarUpload"
    :on-remove="handleRemove"
    list-type="picture-card"
  >    
    <div v-if="fileUrl || imageUrl" class="upload-wrapper" >
      <img :src="fileUrl || imageUrl"
        class="upload-img" />
      <div class="upload-mark-wrapper flex-all-center">
        <div class="afresh-btn flex-all-center font-size-14" style="color:white">重新上传</div>
        <div v-if="isNeedReview"
          class="afresh-btn flex-all-center font-size-14" 
          style="color:white;margin-left:1rem" 
          @click.stop="onClickReview($event)">预览效果</div>
      </div>
    </div>
    <div v-else class="no-img-wrapper flex-column-all-center">
      <i class="el-icon-plus avatar-uploader-icon"></i>
      <span class="font-size-14 upload-text font-opacity-85">上传</span>
    </div>
  </el-upload>
</template>
<script>
import ossClient from "@utils/aliyun.oss.client";
import request from "@request/api/index";
import { getFileSize } from '@utils/getFileSize';
export default {
  name: "Upload",
  props:{
    maxSize:Number,//单位字节
    isNeedReview:Boolean,
    imageUrl: String,
  },
  data() {
    return {
      fileUrl: "",
      uploadConf: {
        accessKeyId: null,
        accessKeySecret: null,
        expiration: null,
        stsToken: null,
        bucket: null,
        endpoint: null,
        expire: null,
        host: null,
        objectKey: null,
        policy: null,
        regionId: null,
        signature: null,
      },
      isLimit:true,//是否未超出图片大小限制
      fileOssUrl:""//上传oss的url
    };
  },
  methods: {
    /**
     * 初始化
     */
    async init(fileName) {
      const result = await request.upload.requestUploadSignature({
        fileName: fileName,
      });

      this.uploadConf.accessKeyId = result.data.data.AccessKeyId;
      this.uploadConf.accessKeySecret = result.data.data.AccessKeySecret;
      this.uploadConf.expiration = result.data.data.Expiration;
      this.uploadConf.stsToken = result.data.data.SecurityToken;
      this.uploadConf.bucket = result.data.data.bucket;
      this.uploadConf.endpoint = result.data.data.endpoint;
      this.uploadConf.expire = result.data.data.expire;
      this.uploadConf.host = result.data.data.host;
      this.uploadConf.objectKey = result.data.data.objectKey;
      this.uploadConf.policy = result.data.data.policy;
      this.uploadConf.regionId = result.data.data.regionId;
      this.uploadConf.signature = result.data.data.signature;
    },
    /**
     * 阿里云OSS上传
     */
    async uploadHttp({ file }) {
      await this.init(file.name);
      ossClient(this.uploadConf)
        .put(this.uploadConf.objectKey, file, {
          ContentType: "image/jpeg",
        })
        .then(({ res, url, name }) => {
          if (res && res.status == 200) {
            this.fileOssUrl = this.uploadConf.objectKey;
            console.log(`阿里云OSS上传图片成功回调`, res, url, name);
          }
        })
        .catch((err) => {
          console.log(`阿里云OSS上传图片失败回调`, err);
        });
    },
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      let arr = file.name.split(".");
      let suffix = arr[arr.length - 1].toLowerCase();
      const isJPEG = suffix === "jpeg";
      const isJPG = suffix === "jpg";
      const isPNG = suffix === "png";
      this.isLimit = file.size < this.maxSize;
      if (!isJPG && !isJPEG && !isPNG) {
        this.$message.error("上传图片只能是 JPEG/JPG/PNG 格式!");
      }
      if (!this.isLimit) {
        this.$message.error("单张图片大小不能超过 " + getFileSize(this.maxSize) + "!");
      }
      return (isJPEG || isJPG || isPNG) && this.isLimit;
    },
    /**
     * 选取图片后回调，获取图片本地的url路径
     */
    imgSaveToUrl(file) {
      let reader = new FileReader();
      reader.onload = () => {
        if(this.isLimit){
          this.fileUrl = reader.result;
          if(this.isNeedReview){
            this.$store.commit("previewLoginBackground",this.fileUrl);
          }
        }
      };
      reader.readAsDataURL(file.raw);
    },
    /**
     * 移除图片
     */
    handleRemove(file) {
      console.log(`移除图片回调`, file);
      this.fileUrl = "";
         this.$store.commit("previewLoginBackground",this.fileUrl);
        console.log('this.$store.state.user.fileUrl',this.$store.state.user.fileUrl)
      this.fileOssUrl = "";
    },
    onClickReview(event){
      event.stopPropagation();
      let newpage = this.$router.resolve({ name: "loginReview" });
      window.open(newpage.href, "_blank");
    }
  },
};
</script>


<style lang="scss" scoped>
.no-img-wrapper{
  width:100%;
  height:100%;
  .upload-text{
    color:black;
    margin-top: 10px;
  } 
}
.upload-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  .upload-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .upload-mark-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba($color: #000000, $alpha: .4);
    border-radius: 4px;
    .afresh-btn{
      width: 82px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #FFFFFF;
    }
  }
}

</style>